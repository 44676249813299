import GATSBY_COMPILED_MDX from "/home/runner/work/jcfl-gatsby-gakka/jcfl-gatsby-gakka/src/sources/comprehensive/main/episode04.mdx";
import React from "react";
import {MDXProvider} from "@mdx-js/react";
import {Link} from "gatsby";
import Button from "../components/elements/Button/Button";
import DivisionAbstruct from "../components/elements/DivisionAbstruct/DivisionAbstruct";
import {KoreanDivisionAbstruct} from "../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../components/elements/Hr/Hr";
import SnsShare from "../components/elements/SnsShare/SnsShare";
import Layout from "../components/layouts/Default/Default";
import {HtmlBody} from "../components/commons/HtmlBody/HtmlBody";
import {useGakka} from "../hooks/useGakka/useGakka";
import Alumnus from "../components/elements/Alumnus/Alumnus";
import Tags from "../components/elements/Tags/Tags";
import * as tags from "../json/tags.json";
import {shuffle} from "../utils/shuffle/shuffle";
import H2 from "../components/elements/H2/H2";
import {ResponsiveBlock} from "../components/layouts/ResponsiveBlock/ResponsiveBlock";
import {CommonHead} from "../components/commons/Head/Head";
var shortcodes = {
  Link: Link
};
var query = "1321051333";
function PageTemplate(_ref) {
  var _data$mdx, _data$mdx$frontmatter, _data$mdx2, _data$mdx2$frontmatte, _data$mdx3, _data$mdx3$frontmatte, _data$mdx4, _data$mdx4$frontmatte, _data$mdx5, _data$mdx5$frontmatte, _data$allMdx$nodes, _data$allMdx;
  var data = _ref.data, children = _ref.children;
  var title = data === null || data === void 0 ? void 0 : (_data$mdx = data.mdx) === null || _data$mdx === void 0 ? void 0 : (_data$mdx$frontmatter = _data$mdx.frontmatter) === null || _data$mdx$frontmatter === void 0 ? void 0 : _data$mdx$frontmatter.title;
  var ce_title = data === null || data === void 0 ? void 0 : (_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : (_data$mdx2$frontmatte = _data$mdx2.frontmatter) === null || _data$mdx2$frontmatte === void 0 ? void 0 : _data$mdx2$frontmatte.ce_description;
  var spaceTop = data === null || data === void 0 ? void 0 : (_data$mdx3 = data.mdx) === null || _data$mdx3 === void 0 ? void 0 : (_data$mdx3$frontmatte = _data$mdx3.frontmatter) === null || _data$mdx3$frontmatte === void 0 ? void 0 : _data$mdx3$frontmatte.spaceTop;
  var spaceBottom = data === null || data === void 0 ? void 0 : (_data$mdx4 = data.mdx) === null || _data$mdx4 === void 0 ? void 0 : (_data$mdx4$frontmatte = _data$mdx4.frontmatter) === null || _data$mdx4$frontmatte === void 0 ? void 0 : _data$mdx4$frontmatte.spaceBottom;
  var isMain = (data === null || data === void 0 ? void 0 : (_data$mdx5 = data.mdx) === null || _data$mdx5 === void 0 ? void 0 : (_data$mdx5$frontmatte = _data$mdx5.frontmatter) === null || _data$mdx5$frontmatte === void 0 ? void 0 : _data$mdx5$frontmatte.isMain) || false;
  var gakka = useGakka();
  var nodes = (_data$allMdx$nodes = (_data$allMdx = data.allMdx) === null || _data$allMdx === void 0 ? void 0 : _data$allMdx.nodes) !== null && _data$allMdx$nodes !== void 0 ? _data$allMdx$nodes : [];
  return React.createElement(Layout, {
    title: title,
    pageType: "SubPage"
  }, React.createElement(HtmlBody, {
    title: title,
    spaceTop: spaceTop,
    spaceBottom: spaceBottom
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)), isMain === true && gakka.pathName === "flight" && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "tw-py-8 tw-text-center md:tw-py-16"
  }, React.createElement(Button, {
    name: "\u8A18\u4E8B\u4E00\u89A7\u3078",
    url: "/flight/main"
  })), React.createElement(Hr, null), React.createElement(SnsShare, {
    path: data.mdx.fields.slug,
    title: title,
    buttonName: "\u623B\u308B",
    buttonUrl: "/flight"
  }), React.createElement(Hr, null), React.createElement(DivisionAbstruct, {
    spaceBottom: true
  })), isMain === true && gakka.pathName === "comprehensive" && React.createElement(React.Fragment, null, React.createElement(Hr, null), React.createElement(SnsShare, {
    path: data.mdx.fields.slug,
    title: ce_title,
    buttonName: "\u623B\u308B",
    buttonUrl: "/comprehensive"
  }), React.createElement(Hr, null), React.createElement(ResponsiveBlock, {
    bgColor: ""
  }, React.createElement(Tags, {
    nodes: nodes,
    tags: tags.tags
  })), React.createElement(Hr, null), React.createElement(ResponsiveBlock, {
    bgColor: ""
  }, React.createElement("div", {
    className: "tw-px-4 tw-py-8 md:tw-pt-16 md:tw-px-0 md:tw-pb-4"
  }, React.createElement("div", {
    className: "tw-mb-8"
  }, React.createElement(H2, {
    title: "\u7DCF\u5408\u82F1\u8A9E\u79D1\u306E\u591A\u69D8\u306A\u30AD\u30E3\u30E9\u3092\u7D39\u4ECB\u3057\u307E\u3059"
  })), React.createElement(Alumnus, {
    nodes: shuffle(nodes).slice(0, 4)
  }))), React.createElement(Hr, null), React.createElement(DivisionAbstruct, {
    spaceBottom: true
  })), gakka.pathName === "korean" && React.createElement(React.Fragment, null, React.createElement(Hr, null), React.createElement(KoreanDivisionAbstruct, {
    spaceBottom: true
  })), isMain === false && gakka.pathName && gakka.pathName !== "korean" && React.createElement(React.Fragment, null, React.createElement(Hr, null), React.createElement(DivisionAbstruct, {
    spaceBottom: true
  })));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref2) {
  var _data$mdx6, _data$mdx6$frontmatte, _data$mdx7, _data$mdx7$frontmatte, _data$mdx8, _data$mdx8$frontmatte, _data$mdx9, _data$mdx9$frontmatte, _data$mdx10, _data$mdx10$frontmatt, _data$mdx11, _data$mdx11$frontmatt, _data$mdx12, _data$mdx12$frontmatt, _data$mdx13, _data$mdx13$frontmatt, _data$mdx14, _data$mdx14$frontmatt, _data$mdx15, _data$mdx15$frontmatt;
  var data = _ref2.data;
  var title = (data !== null && data !== void 0 && (_data$mdx6 = data.mdx) !== null && _data$mdx6 !== void 0 && (_data$mdx6$frontmatte = _data$mdx6.frontmatter) !== null && _data$mdx6$frontmatte !== void 0 && _data$mdx6$frontmatte.title ? data === null || data === void 0 ? void 0 : (_data$mdx7 = data.mdx) === null || _data$mdx7 === void 0 ? void 0 : (_data$mdx7$frontmatte = _data$mdx7.frontmatter) === null || _data$mdx7$frontmatte === void 0 ? void 0 : _data$mdx7$frontmatte.title : (data === null || data === void 0 ? void 0 : (_data$mdx8 = data.mdx) === null || _data$mdx8 === void 0 ? void 0 : (_data$mdx8$frontmatte = _data$mdx8.frontmatter) === null || _data$mdx8$frontmatte === void 0 ? void 0 : _data$mdx8$frontmatte.ce_title) + " " + (data === null || data === void 0 ? void 0 : (_data$mdx9 = data.mdx) === null || _data$mdx9 === void 0 ? void 0 : (_data$mdx9$frontmatte = _data$mdx9.frontmatter) === null || _data$mdx9$frontmatte === void 0 ? void 0 : _data$mdx9$frontmatte.ce_label)) + " - " + data.site.siteMetadata.title;
  var description = data !== null && data !== void 0 && (_data$mdx10 = data.mdx) !== null && _data$mdx10 !== void 0 && (_data$mdx10$frontmatt = _data$mdx10.frontmatter) !== null && _data$mdx10$frontmatt !== void 0 && _data$mdx10$frontmatt.description ? data === null || data === void 0 ? void 0 : (_data$mdx11 = data.mdx) === null || _data$mdx11 === void 0 ? void 0 : (_data$mdx11$frontmatt = _data$mdx11.frontmatter) === null || _data$mdx11$frontmatt === void 0 ? void 0 : _data$mdx11$frontmatt.description : data === null || data === void 0 ? void 0 : (_data$mdx12 = data.mdx) === null || _data$mdx12 === void 0 ? void 0 : (_data$mdx12$frontmatt = _data$mdx12.frontmatter) === null || _data$mdx12$frontmatt === void 0 ? void 0 : _data$mdx12$frontmatt.ce_description;
  console.log(data.mdx);
  var imagePath = data !== null && data !== void 0 && (_data$mdx13 = data.mdx) !== null && _data$mdx13 !== void 0 && (_data$mdx13$frontmatt = _data$mdx13.frontmatter) !== null && _data$mdx13$frontmatt !== void 0 && _data$mdx13$frontmatt.thumbnail ? data === null || data === void 0 ? void 0 : (_data$mdx14 = data.mdx) === null || _data$mdx14 === void 0 ? void 0 : (_data$mdx14$frontmatt = _data$mdx14.frontmatter) === null || _data$mdx14$frontmatt === void 0 ? void 0 : _data$mdx14$frontmatt.thumbnail : data === null || data === void 0 ? void 0 : (_data$mdx15 = data.mdx) === null || _data$mdx15 === void 0 ? void 0 : (_data$mdx15$frontmatt = _data$mdx15.frontmatter) === null || _data$mdx15$frontmatt === void 0 ? void 0 : _data$mdx15$frontmatt.ce_thumbnail;
  return React.createElement(CommonHead, {
    ogTitle: title,
    ogDescription: description,
    ogImageFilepath: imagePath
  }, React.createElement("title", null, title));
};
